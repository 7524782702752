import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/advantage.jpg';
import TwoCol from '../components/rows/twocol/index';

import Follow from '../components/rows/cta/follow';
import Cta from '../components/rows/cta/index';

export default function advantages({ data }) {
  return (
    <Layout>
      <Seo title="The PACE advantage" />
      <Hero title="The PACE advantage" banner={Banner} />
      {data.allStrapiAdvantage.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <TwoCol
            title={node.advantage1.title}
            description={node.advantage1.description}
            image={node.advantage1.image.localFile.publicURL}
          />
          <Cta />
          <TwoCol
            reverse
            title={node.advantage2.title}
            description={node.advantage2.description}
            image={node.advantage2.image.localFile.publicURL}
          />
        </React.Fragment>
      ))}
      <div className="mt-56">
        <Follow />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query advantages {
    allStrapiAdvantage {
      edges {
        node {
          id # id of the node
          advantage1 {
            id
            title
            description
            image {
              localFile {
                publicURL
              }
            }
          }
          advantage2 {
            id
            title
            description
            image {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
